<template>
    <div class="t">
        <el-button>测试</el-button>
    </div>
</template>

<script>
    import axios from "@/libs/axios";

    export default {
        name: "Test",
        data() {
            return {}
        },
        mounted() {
            axios.request({
                url: '/smartHome/v1/scene/getScene',
                method: 'get',
                data: {},
            }).then(response => {
                console.log(response.data.data);
            }).catch(() => {
                console.log("error");
            });
        },
        methods: {}
    }
</script>

<style scoped lang="scss">
    .t {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .tt {
            color: red;
        }
    }
</style>
